
import { useRouter } from 'vue-router'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    location: String
  },
  setup(props, {emit}) {
    const searchValue = ref('')
    const router = useRouter()
    // const { location } = toRefs(props)
    const handleLocationChange = () => {
      router.push('/citys')
    }
    const handleSearch = () => {
      emit('handleSearch', searchValue.value)
    }
    watch(() => props.location, (cur) => {
      console.log(cur)
    })
    return {
      searchValue,
      handleSearch,
      handleLocationChange
    }
  },
})
