<template>
  <div class="content">
    <LocationSearch :location='location' />
    <div class="content-info">
      <div class="search-history">
        <span class="text">搜索历史</span>
        <i class="iconfont icon-delete"></i>
      </div>
      <div class="history-list">
        <div class="list-item">
          <i class="iconfont icon-danseyuandian"></i>
          <span class="text">蓝月路</span>
        </div>
        <div class="list-item">
          <i class="iconfont icon-danseyuandian"></i>
          <span class="text">我的位置--蓝月路</span>
        </div>
        <div class="list-item">
          <i class="iconfont icon-danseyuandian"></i>
          <span class="text">365A路 开往 开罗大道</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import useLocationEffect from '@/hooks/locationHooks'
import LocationSearch from '@/components/LocationSearch.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    LocationSearch
  },
  setup() {
    const { location, handleLocationChange } = useLocationEffect()
    return {
      handleLocationChange,
      location
    }
  },
})
</script>


<style lang="less" scoped>
.content {
  height: 100%;
  background-color: @base-background-color;
}

.content-info {
  margin-top: 8px;
  padding: 16px 32px;
  .search-history {
    display: flex;
    font-size: 24px;
    .text {
      flex: 1;
    }
    .iconfont {
      font-size: 24px;
    }
  }

  .history-list {
    .list-item {
      margin: 12px 0;
      padding: 12px 0;
      font-size: 24px;
      .text {
        vertical-align: text-bottom;
      }
    }
  }
}
</style>