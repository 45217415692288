
import useLocationEffect from '@/hooks/locationHooks'
import LocationSearch from '@/components/LocationSearch.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    LocationSearch
  },
  setup() {
    const { location, handleLocationChange } = useLocationEffect()
    return {
      handleLocationChange,
      location
    }
  },
})
