<template>
  <div class="search">
    <span class="location" @click="handleLocationChange">
      {{ location }}
      <van-icon name="arrow-down" />
    </span>
    <van-search
      class="search-bar"
      show-action
      shape="round"
      v-model="searchValue"
      @search="handleSearch"
      placeholder="查线路、站点"
    >
      <template #action>
        <van-button class="action" type="primary" size="small" @click.stop.prevent="handleSearch">搜索</van-button>
        <!-- <div class="action" @click.stop.prevent="handleSearch">搜索</div> -->
      </template>
    </van-search>
  </div>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    location: String
  },
  setup(props, {emit}) {
    const searchValue = ref('')
    const router = useRouter()
    // const { location } = toRefs(props)
    const handleLocationChange = () => {
      router.push('/citys')
    }
    const handleSearch = () => {
      emit('handleSearch', searchValue.value)
    }
    watch(() => props.location, (cur) => {
      console.log(cur)
    })
    return {
      searchValue,
      handleSearch,
      handleLocationChange
    }
  },
})
</script>


<style lang="less" scoped>
.search {
  width: 100%;
  display: flex;
  padding: 16px 0 16px 16px;
  height: 100px;
  background: #fff;
  line-height: 68px;
  box-sizing: border-box;
  .location {
    font-size: @base-font-size;
  }
  .search-bar {
    height: 100%;
    flex: 1;
  }
  ::v-deep .van-search__action {
    margin-top: -8px;
  }
  .action {
    // margin-top: -6px;
    // font-size: @base-font-size;
    // width: 100px;
    text-align: center;
  }
}
</style>