import { Ref, toRefs } from 'vue'
import { useStore } from 'vuex'

interface useLocationEffect {
  location: Ref<string>
  handleLocationChange: () => void
}

const useLocationEffect = (): useLocationEffect => {
  const store = useStore()
  console.log('store', store.state.city.city)
  const { location } = toRefs(store.state.city.city)
  const handleLocationChange = () => {
    console.log('handleLocationChange')
  }
  return {
    location,
    handleLocationChange
  }
}

export default useLocationEffect